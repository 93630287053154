exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accountants-and-bookkeepers-js": () => import("./../../../src/pages/accountants-and-bookkeepers.js" /* webpackChunkName: "component---src-pages-accountants-and-bookkeepers-js" */),
  "component---src-pages-articles-accessing-the-on-boarding-wizard-js": () => import("./../../../src/pages/articles/accessing-the-on-boarding-wizard.js" /* webpackChunkName: "component---src-pages-articles-accessing-the-on-boarding-wizard-js" */),
  "component---src-pages-articles-account-registration-guide-js": () => import("./../../../src/pages/articles/account-registration-guide.js" /* webpackChunkName: "component---src-pages-articles-account-registration-guide-js" */),
  "component---src-pages-articles-accountants-files-folder-js": () => import("./../../../src/pages/articles/accountants-files-folder.js" /* webpackChunkName: "component---src-pages-articles-accountants-files-folder-js" */),
  "component---src-pages-articles-application-terminates-when-sent-to-background-js": () => import("./../../../src/pages/articles/application-terminates-when-sent-to-background.js" /* webpackChunkName: "component---src-pages-articles-application-terminates-when-sent-to-background-js" */),
  "component---src-pages-articles-authorised-files-folder-js": () => import("./../../../src/pages/articles/authorised-files-folder.js" /* webpackChunkName: "component---src-pages-articles-authorised-files-folder-js" */),
  "component---src-pages-articles-client-agent-nomination-js": () => import("./../../../src/pages/articles/client-agent-nomination.js" /* webpackChunkName: "component---src-pages-articles-client-agent-nomination-js" */),
  "component---src-pages-articles-emails-automatically-deleted-from-mailbox-js": () => import("./../../../src/pages/articles/emails-automatically-deleted-from-mailbox.js" /* webpackChunkName: "component---src-pages-articles-emails-automatically-deleted-from-mailbox-js" */),
  "component---src-pages-articles-file-manager-dashboard-js": () => import("./../../../src/pages/articles/file-manager-dashboard.js" /* webpackChunkName: "component---src-pages-articles-file-manager-dashboard-js" */),
  "component---src-pages-articles-first-time-login-two-factor-authentication-js": () => import("./../../../src/pages/articles/first-time-login-two-factor-authentication.js" /* webpackChunkName: "component---src-pages-articles-first-time-login-two-factor-authentication-js" */),
  "component---src-pages-articles-get-started-with-synkli-js": () => import("./../../../src/pages/articles/get-started-with-synkli.js" /* webpackChunkName: "component---src-pages-articles-get-started-with-synkli-js" */),
  "component---src-pages-articles-log-book-guide-js": () => import("./../../../src/pages/articles/log-book-guide.js" /* webpackChunkName: "component---src-pages-articles-log-book-guide-js" */),
  "component---src-pages-articles-log-book-setup-js": () => import("./../../../src/pages/articles/log-book-setup.js" /* webpackChunkName: "component---src-pages-articles-log-book-setup-js" */),
  "component---src-pages-articles-log-book-usage-js": () => import("./../../../src/pages/articles/log-book-usage.js" /* webpackChunkName: "component---src-pages-articles-log-book-usage-js" */),
  "component---src-pages-articles-security-and-authentication-settings-js": () => import("./../../../src/pages/articles/security-and-authentication-settings.js" /* webpackChunkName: "component---src-pages-articles-security-and-authentication-settings-js" */),
  "component---src-pages-articles-setting-up-tax-profile-js": () => import("./../../../src/pages/articles/setting-up-tax-profile.js" /* webpackChunkName: "component---src-pages-articles-setting-up-tax-profile-js" */),
  "component---src-pages-articles-sign-up-with-invite-email-js": () => import("./../../../src/pages/articles/sign-up-with-invite-email.js" /* webpackChunkName: "component---src-pages-articles-sign-up-with-invite-email-js" */),
  "component---src-pages-articles-user-files-folder-js": () => import("./../../../src/pages/articles/user-files-folder.js" /* webpackChunkName: "component---src-pages-articles-user-files-folder-js" */),
  "component---src-pages-blogs-facing-challenges-in-your-business-js": () => import("./../../../src/pages/blogs/facing-challenges-in-your-business.js" /* webpackChunkName: "component---src-pages-blogs-facing-challenges-in-your-business-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-what-is-carbon-accounting-js": () => import("./../../../src/pages/blogs/what-is-carbon-accounting.js" /* webpackChunkName: "component---src-pages-blogs-what-is-carbon-accounting-js" */),
  "component---src-pages-blogs-what-is-labour-productivity-and-how-does-it-impact-your-business-js": () => import("./../../../src/pages/blogs/what-is-labour-productivity-and-how-does-it-impact-your-business.js" /* webpackChunkName: "component---src-pages-blogs-what-is-labour-productivity-and-how-does-it-impact-your-business-js" */),
  "component---src-pages-case-studies-enhancing-accountant-client-communication-for-a-consultancy-firm-js": () => import("./../../../src/pages/case-studies/enhancing-accountant-client-communication-for-a-consultancy-firm.js" /* webpackChunkName: "component---src-pages-case-studies-enhancing-accountant-client-communication-for-a-consultancy-firm-js" */),
  "component---src-pages-case-studies-simplifying-financial-management-for-a-freelance-graphic-designer-js": () => import("./../../../src/pages/case-studies/simplifying-financial-management-for-a-freelance-graphic-designer.js" /* webpackChunkName: "component---src-pages-case-studies-simplifying-financial-management-for-a-freelance-graphic-designer-js" */),
  "component---src-pages-case-studies-streamlining-financial-management-for-small-retail-business-js": () => import("./../../../src/pages/case-studies/streamlining-financial-management-for-small-retail-business.js" /* webpackChunkName: "component---src-pages-case-studies-streamlining-financial-management-for-small-retail-business-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-help-and-support-js": () => import("./../../../src/pages/help-and-support.js" /* webpackChunkName: "component---src-pages-help-and-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-small-businesses-js": () => import("./../../../src/pages/small-businesses.js" /* webpackChunkName: "component---src-pages-small-businesses-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-user-email-verification-js": () => import("./../../../src/pages/user-email-verification.js" /* webpackChunkName: "component---src-pages-user-email-verification-js" */)
}

